import { Link } from 'gatsby';
import React, { FC } from 'react';
import IconList from './IconList';

const AppFooter: FC = () => {
  const year = new Date().getFullYear();

  return (
    <div className="w-full text-center bottom-0 text-lg border-grey p-4 bg-black text-white">
      <div className="font-maintitle text-center text-2xl md:text-4xl my-3">
        <Link to="/">LUDIVINE</Link>
      </div>
      <div className="grid  grid-rows-3">
        <IconList
          className="row-start-1 text-end"
          iconClassName="md:mx-16 mx-9 my-3"
        />
        <p className='font-serif text-white my-3'>&quot;Do the best you can until you know better. Then when you know
          better, do better.&quot;</p>
        <div className="text-center text-xs m-auto row-start-3">
          Copyright © Achouri Ludivine {year} | All rights reserved
          <div className="text-center text-xs m-auto ">
            <Link to="/legal-notice">Terms & Conditions</Link>
          </div>
        </div>

      </div>
    </div>
  );
};

export default AppFooter;
