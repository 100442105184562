import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import AppFooter from './Footer';
import AppHeader from './Header';

const App: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="flex flex-col min-h-screen text-center justify-between">
    <Helmet>
      <html lang="fr" />
      <title>Ludivine Achouri</title>
    </Helmet>
    <AppHeader />
    {children}
    <AppFooter />
  </div>
);

export default App;
