import React, { FC } from 'react';

const IconItem: FC<{ link: string; iconClassName: string }> = ({
  children,
  link,
  iconClassName,
}) => (
  <li className={iconClassName}>
    <a href={link} rel="noreferrer" target="_blank">
      {children}
    </a>
  </li>
);

export default IconItem;
