import { Link } from 'gatsby';
import React, { FC } from 'react';

const SideNav: FC<{
  width: string;
  closeNav: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ width, closeNav }) => (
  <div className="sidenav" style={{ width }}>
    <button
      className="float-right p-3 t-0 text-xl "
      onClick={closeNav}
      type="button"
    >
      X
    </button>
    <ul className=" font-category mt-7 text-3xl ">
      <li className='sidenav-li' />

      <li className="sidenav-li mt-6 font-maintitle text-5xl" >LUDIVINE</li>
      <li className="sidenav-li mt-6 font-serif">
        <Link to="/category/development">DEVELOPMENT</Link>
      </li>
      <li className="sidenav-li mt-6 font-serif">
        <Link to="/category/life">LIFESTYLE</Link>
      </li>
      <li className="sidenav-li mt-6 font-serif">
        <Link to="/about-me">ABOUT</Link>
      </li>
    </ul>
  </div>
);

export default SideNav;
