import { Link } from 'gatsby';
import React, { FC, useState } from 'react';
import {
  FaGithub,
  FaLinkedinIn,
  FaPortrait,
} from 'react-icons/fa';
import IconList from '../Footer/IconList';

import SideNav from './Sidenav';

const AppHeader: FC = () => {
  const [width, setWidth] = useState<string>('0%');

  const openSidenav = () => {
    setWidth('100%');
  };
  const closeSidenav = () => {
    setWidth('0%');
  };

  return (
    <div className="text-center w-full inset-0">
      <div className="p-5">
        <div className="grid grid-cols-2 text-center m-auto items-center">
          <div className="py-1">
            <SideNav closeNav={closeSidenav} width={width} />
            <div className="font-maintitle text-center text-3xl md:text-6xl font-medium">
              <Link to="/">LUDIVINE</Link>
            </div>
          </div>
          <div className=" hidden md:grid md:grid-cols-1 md:items-center  m-auto md:w-auto  pt-6 md:pt-0">
            <div className="font-article text-lg">
              <ul>
                <li className=" inline-block px-10 font-serif">
                  <Link to="/category/development">DEVELOPMENT</Link>
                </li>
                <li className=" inline-block px-10 font-serif">
                  <Link to="/category/life">LIFE</Link>
                </li>
                <li className=" inline-block px-10 font-serif">
                  <Link to="/about-me">ABOUT</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-row-reverse row-reverse col-start-2 row-start-1 md:hidden ">
            <button
              className="  px-3 py-2 text-grey hover:text-white hover:border-white"
              onClick={openSidenav}
              type="button"
            >
              <svg
                className="fill-current h-5 w-5"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default AppHeader;
