import React, { FC } from 'react';
import {
  FaGithub,
  FaInstagram,
  FaLinkedinIn,
  FaPortrait,
} from 'react-icons/fa';
import IconItem from './IconItem';

const IconList: FC<{ className: string; iconClassName: string }> = ({
  className,
  iconClassName,
}) => (
  <div className={className}>
    <ul>
      <IconItem
        iconClassName={iconClassName}
        link="https://ludivineachouri.com/"
      >
        <FaPortrait title="Portfolio" />
      </IconItem>
      <IconItem
        iconClassName={iconClassName}
        link="https://github.com/Lachouri"
      >
        <FaGithub title="Github" />
      </IconItem>
      <IconItem
        iconClassName={iconClassName}
        link="https://fr.linkedin.com/in/ludivine-achouri"
      >
        <FaLinkedinIn title="Linkedin" />
      </IconItem>
    </ul>
  </div>
);

export default IconList;
